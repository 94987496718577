import styled, { css } from 'styled-components';

import { TabStyledProps, TabActiveStyledProps } from './types';
import Link from '../../atoms/Link';
import Typography from '../../styles/Typography';
import { transition } from '../../../common/mixins';
import media from '../../../common/MediaQueries';
import Button from '../../atoms/Button';

export const TabsTogglersList = styled.ul`
  display: flex;
  width: calc(100% + 1rem);
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  ${({ theme }) => theme.shadows.insetOutline}
`;

export const TabsTogglersListItem = styled.li<TabActiveStyledProps>`
  ${transition()}
  display: flex;
  width: 100%;

  &:not(:first-child) {
    margin-left: 0.5rem;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      ${({ theme }) => theme.shadows.insetBorder}
    `}
`;

export const TabsTogglerAction = styled(Button)`
  ${({ theme }) => theme.textS};
  background: none;
  text-transform: uppercase;
  padding: 0.875rem 0.5rem;
  width: 100%;
  white-space: nowrap;
`;

export const TabsContentBox = styled.div`
  padding-top: 1rem;
`;

export const TabsContent = styled.div<TabActiveStyledProps>`
  display: none;

  ${({ isActive }) =>
    isActive &&
    css`
      display: flex;
      flex-direction: column;
    `}
`;

export const TabsContentHeading = styled(Typography).attrs({
  forwardedAs: 'h2',
})`
  margin-bottom: 1.5rem;
  letter-spacing: -0.017em;
  ${({ theme }) => theme.headingXL};
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  color: ${({ theme }) => theme.colors.additional.dark};
`;

export const TabsContentLabel = styled(Typography)`
  display: inline-flex;
  align-items: center;

  &:not(:first-child) {
    margin-top: 1rem;
  }
`;

export const TabsContentIcon = styled.span`
  width: 0.875rem;
  height: 0.875rem;
  font-size: 0.875rem;
  border-radius: 50%;
  margin-right: 0.625rem;
  color: ${({ theme }) => theme.colors.neutral.neutral10};
  background-color: ${({ theme }) => theme.colors.secondary.hover};
`;

export const TabsContentLink = styled(Link)``;

export const Tabs = styled.div<TabStyledProps>`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${({ isAlt }) =>
    isAlt &&
    css`
      padding: 2.5rem;
      border: 1px solid ${({ theme }) => theme.colors.additional.accentGray1};
      ${TabsTogglerAction} {
        white-space: initial;
      }
      ${TabsContentLink} {
        width: 100%;
      }
      @media ${media.phone} {
        ${TabsContent} {
          justify-content: flex-start;
        }
      }
      @media ${media.tablet} {
        padding: 3.75rem;
      }
    `};
`;
