import React from 'react';

import * as Styled from './styles';
import { TabsProps } from './types';
import { useSelected } from './hooks';

import RichText from '../../molecules/RichText';
import Link from '../../atoms/Link';
import { LinkMode } from '../../atoms/Link/enums';
import { Box } from '../../styles/Grid/styles';

const Tabs: React.FC<TabsProps> = ({ items, initialyOpenKey, onSelect, isAlt }) => {
  const { selectedTabKey, onChangeTab } = useSelected(
    items[0] && items[0].key,
    onSelect,
    initialyOpenKey
  );

  return (
    <Styled.Tabs {...{ isAlt }}>
      <Styled.TabsTogglersList role="tablist">
        {items.map(({ key, heading }) => (
          <Styled.TabsTogglersListItem
            {...{ key }}
            isActive={selectedTabKey === key}
            role="presentation"
          >
            <Styled.TabsTogglerAction
              type="button"
              id={`${key}-tab`} // id and aria labels for accessibility
              aria-controls={`${key}-panel`}
              aria-selected={selectedTabKey === key}
              mode={LinkMode.HasPlainLook}
              role="tab"
              onClick={() => onChangeTab(key)}
            >
              {heading}
            </Styled.TabsTogglerAction>
          </Styled.TabsTogglersListItem>
        ))}
      </Styled.TabsTogglersList>
      <Styled.TabsContentBox>
        {items.map(({ key, content, buttonLink, annotation }) => {
          const { link, label: buttonLabel } = buttonLink;
          return (
            <Styled.TabsContent
              {...{ key }}
              aria-labelledby={`${key}-tab`}
              id={`${key}-panel`}
              isActive={selectedTabKey === key}
              role="tabpanel"
            >
              {content && <RichText data={content} />}
              
              {/* Only show the button if a buttonLabel is set */}
              {buttonLabel && (
                <Box pt={isAlt ? '2.5rem' : ['2.5rem', '5rem']} mt="auto">
                  <Styled.TabsContentLink {...link}>{buttonLabel}</Styled.TabsContentLink>
                </Box>
              )}

              {annotation && (
                <Box pt="1.5rem">
                  <RichText data={annotation} />
                </Box>
              )}
            </Styled.TabsContent>
          );
        })}
      </Styled.TabsContentBox>
    </Styled.Tabs>
  );
};

export default Tabs;
