import { graphql } from 'gatsby';
import React from 'react';

import BookNow from '../../BookNow';
import { BookNowSectionProps } from './types';
import { TabsItemProps } from '../../../organisms/Tabs/types';

import { getHref } from '../../../../utils/sanityTypes';

const BookNowSection: React.VFC<BookNowSectionProps> = ({ title, subtitle, description, tabs }) => {
  return (
    <BookNow
      data={{
        heading: {
          title: title ?? '',
          subtitle: subtitle ?? '',
          content: description ?? '',
        },
        tabs: (tabs ?? []).map<TabsItemProps>((singleTab) => {
          const { _key: key, heading: head, content, buttonLink } = singleTab ?? {};
          const { buttonLabel, link } = buttonLink ?? {};

          return {
            key: key ?? '',
            heading: head ?? '',
            content: content ?? undefined,
            buttonLink: {
              label: buttonLabel ?? '',
              link: { to: getHref(link) },
            },
          };
        }),
      }}
    />
  );
};

export const fragment = graphql`
  fragment BookNowSectionFragment on SanityBookNowSection {
    _key
    _type
    title
    subtitle
    description
    tabs {
      _key
      _type
      heading
      content: _rawContent(resolveReferences: { maxDepth: 10 })
      buttonLink {
        buttonLabel
        link {
          ...LinkFragment
        }
      }
    }
    backgroundColor
    gutterTop
    gutterBottom
    isFullWidth
  }
`;

export default BookNowSection;
