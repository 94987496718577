import React from 'react';

import { BookNowProps } from './types';
import * as Styled from './styles';

import Heading from '../../molecules/Heading';
import Tabs from '../../organisms/Tabs';

const BookNow: React.FC<BookNowProps> = ({ data }) => {
  const {
    heading: { title, subtitle, content },
    tabs,
  } = data;

  return (
    <Styled.BookNow>
      <Styled.BookNowHeading>
        <Heading {...{ title, subtitle, content }} />
      </Styled.BookNowHeading>
      <Styled.BookNowTabs>
        <Tabs items={tabs} isAlt />
      </Styled.BookNowTabs>
    </Styled.BookNow>
  );
};

export default BookNow;
