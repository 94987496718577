import styled from 'styled-components';

import { Inner } from '../../styles/Grid/styles';
import media from '../../../common/MediaQueries';
import { HeadingContent } from '../../molecules/Heading/styles';

export const BookNow = styled(Inner)`
  @media ${media.phone} {
    display: flex;
    justify-content: space-between;
  }
`;

export const BookNowHeading = styled.div`
  ${HeadingContent} {
    color: ${({ theme }) => theme.colors.additional.accentGray2};
  }

  @media ${media.phone} {
    padding-top: 3.25rem;
    margin-right: 2rem;
    max-width: 32rem;
    width: 100%;
  }

  @media ${media.tablet} {
    padding-top: 4.5rem;
  }
`;

export const BookNowTabs = styled.div`
  margin-top: 3rem;

  @media ${media.phone} {
    margin-top: 0;
    max-width: 24.625rem;
    width: 100%;
  }
`;
