import { useCallback, useState } from 'react';

export const useSelected = (
  currentlySelected: string,
  onSelect: ((key: string) => void) | undefined,
  initialyOpenKey: string | undefined
) => {
  const initialTabKey = initialyOpenKey || currentlySelected;
  const [selectedTabKey, setSelectedTabKey] = useState(initialTabKey);

  const onChangeTab = useCallback(
    (key: string) => {
      setSelectedTabKey(key);
      onSelect?.(key);
    },
    [onSelect]
  );

  return { selectedTabKey, onChangeTab };
};
